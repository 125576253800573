import FloatingButton from "@shared/components/FloatingButton";
import { getTimestamp } from "@shared/functions";
import { useAlert } from "react-alert";
import localDB from "../Hooks/usePouch";
import AddIcon from "@material-ui/icons/Add";

const PouchTesting = () => {
  const db = localDB("docs-test", { fetch_docs: true, include_docs: true });
  const alert = useAlert();

  const handleCreateGasto = async () => {
    const gUniqueId = getTimestamp().toString();

    const data = {
      gUniqueId,
      name: "ramon name",
    };

    const result = await db.putDoc({ _id: gUniqueId, ...data }, { triggerRefresh: gUniqueId });

    if (result.ok === true) {
      alert.success("Gasto Registrado!", { position: "bottom center" });
    }
  };

  const handleDeleteGasto = async () => {
    const result = await db.deleteDoc(db.docs[0]._id, db.docs[0]._rev, { triggerRefresh: db.docs[0]._id });

    if (result.ok === true) {
      alert.error("Gasto Borrado!");
    }
  };

  return (
    <div className="container mt-3 mb-5">
      <span className="text-info" onClick={handleCreateGasto}>
        <FloatingButton icon={<AddIcon />} tooltip="Crear Gasto" goto="" />
      </span>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nota</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {db.docs.map((gasto) => (
            <tr key={gasto.gUniqueId}>
              <td className="font-italic">{gasto.gUniqueId}</td>
              <td className="font-italic">{gasto.name}</td>
              <td onClick={handleDeleteGasto}>Delete</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PouchTesting;
